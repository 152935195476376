<template>
  <ModalContainer>
    <div class="success">
      <div class="img-container">
        <svg
          width="6rem"
          height="6rem"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6 13.8L8.425 11.625C8.24167 11.4417 8.01267 11.3543 7.738 11.363C7.46267 11.371 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.0833 16.0833 10.3167 16.175 10.6 16.175C10.8833 16.175 11.1167 16.0833 11.3 15.9L16.975 10.225C17.1583 10.0417 17.246 9.81233 17.238 9.537C17.2293 9.26233 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
            fill="var(--success-color)"
          />
        </svg>
      </div>
      <h2 class="title primary-title">
        {{
          $t("registrationExem.success.title", { number: period.period_number })
        }}
      </h2>
      <div class="primary-text">
        <p class="subtitle">
          {{ $t("registrationExem.success.subtitle", { payment_place: $t([6, 7, 13].includes(data?.region.id) ? "registrationExem.success.payment_places.fergana" : "registrationExem.success.payment_places.tashkent")}) }}
          <!-- {{ $t("registrationExem.success.subtitle", { payment_place: $t("registrationExem.success.payment_places.tashkent")}) }} -->
        </p>
      </div>
      <div class="description">
        <p>
          {{ data?.region.name }} – {{ i18n.global.locale.value == 'ko' ? getFullDate(data?.exam_time, false) :  handleDate(data?.exam_time, 'dd mmmm') }},
            {{ i18n.global.locale.value != 'ko' ? handleDate(data?.exam_time, 'yyyy') : ''}}
          <br />

          {{ data?.address }}
        </p>
        <p>{{ $t("registrationExem.doc") }}</p>

        <!-- <p>{{ $t("registrationExem.exam.ibt") }}</p> -->
        <p>{{ period.is_ibt ? $t("registrationExem.exam.ibt") : $t("registrationExem.exam.pbt") }}</p>
        <p>
          {{
            $t("registrationExem.pay_period", {
              start: i18n.global.locale.value == 'ko' ?  getFullDate(data?.exam_time, true) : handleDate(period.payment_start_date, 'dd mmmm'),
              end: i18n.global.locale.value == 'ko' ?  getFullDate(data?.exam_time, true) : handleDate(period.payment_end_date, 'dd mmmm'),
            })
          }}
        </p>
      </div>
      <div class="btn">
        <PrimaryLink routeName="Home" :title="$t('registrationExem.home')" />
        <PrimaryLink routeName="AccountAnketa" :title="$t('personPage.anketa')" />
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from "../ModalContainer.vue";
import { useLanguage } from "@/utils/language";
import { useRouter } from "vue-router";
import { useFormatter } from "@/utils/formatter";
import { useStore } from "vuex";
import { computed } from "vue";
import PrimaryLink from "../PrimaryLink.vue";

export default {
  components: { ModalContainer, PrimaryLink },
  props: ["data"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const { i18nRoute } = useLanguage();

    const buttonSuccess = () => {
      router.push(
        i18nRoute({
          name: "AccountInfo",
        })
      );
    };

    const formatDate = (date, _) => {
      console.log(_)
     return  Date.parse(date).toLocaleString('ru',{month:'long'})
    }

    return {
      period: computed(() => store.getters["period/getPeriod"]),
      buttonSuccess,
      formatDate,
      ...useLanguage(),
      ...useFormatter(),
    };
  },
};
</script>

<style lang="scss" scoped>
.success {
  background: var(--white);
  padding: 2em;
  max-width: 40rem;
  margin: 0 auto;
  border-radius: 1.13rem;
  .img-container {
    width: 100%;
    text-align: center;
    margin-bottom: 1.69rem;

    .img {
      background: transparent;
      width: 7.5rem;
      margin: 0 auto;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 1.25rem;
    gap: 1.88rem;
  }
  .title {
    text-align: center;
    margin: 0 auto;
  }
  .subtitle {
    font-weight: 600;
    line-height: 2.4;
  }
  .description {
    margin-top: 0.5em;
    font-weight: 700;
    font-size: 1.14rem;
    line-height: 222.4%;
  }
  .next-btn {
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 767px) {
  .success {
    background: #fff;
    padding: 2em;
    max-width: 785px;
    margin: 0 auto;
    .img-container {
      width: 100%;
      text-align: center;
      margin-bottom: 27px;
      .img {
        background: transparent;
        width: 90px;
        margin: 0 auto;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-content: center;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 2;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }
    .subtitle {
      font-weight: 600;
      font-size: 14px;
      line-height: 2.4;
    }
    .description {
      margin-top: 2em;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
    }
    .next-btn {
      margin-top: 1em;
      font-size: 12px;
      width: max-content;
    }
  }
}
</style>
