<template>
  <ModalContainer v-if="difference > 0">
    <transition name="fade" appear>
      <div class="timer-container">
        <span v-if="days != 0" class="day">{{ days }} {{ $t("date.d") }}</span>
        <div class="time">
          <span
            >{{ hours }}: {{ minutes > 9 ? minutes : "0" + minutes }}:
            {{ seconds > 9 ? seconds : "0" + seconds }}</span
          >
        </div>
        <div class="descr">{{ $t("registrationExem.toExam") }}</div>
        <div class="tash">
          {{
            $t("registrationExem.start_exam_tahkent", {
              date: handleDate(date),
              time: handleTime(date),
            })
          }}
        </div>
        <PrimaryLink :title="$t('registrationExem.home')" routeName="Home" />
      </div>
    </transition>
    <transition name="fade" appear>
      <div class="error" v-if="errorModal == null">
        <p>
          {{ $t("registrationExem.noInfo") }}
        </p>
        <PrimaryLink :title="$t('registrationExem.home')" routeName="Home" />
      </div>
    </transition>
  </ModalContainer>
</template>

<script>
import ModalContainer from "@/components/ModalContainer.vue";
import { computed, onBeforeMount, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { useFormatter } from "@/utils/formatter";
import PrimaryLink from "../PrimaryLink.vue";

export default {
  components: {
    ModalContainer,
    PrimaryLink,
  },
  props: ["date"],
  setup(props) {
    const store = useStore();
    const { date } = toRefs(props);
    const parts = reactive({
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
    });
    const difference = ref();

    function countdownTimer() {
      difference.value = new Date(date.value) - new Date();
      if (difference.value > 0) {
        parts.days = Math.floor(difference.value / (1000 * 60 * 60 * 24));
        parts.hours = Math.floor((difference.value / (1000 * 60 * 60)) % 24);
        parts.minutes = Math.floor((difference.value / 1000 / 60) % 60);
        parts.seconds = Math.floor((difference.value / 1000) % 60);
      }
    }
    let timer;
    onMounted(() => {
      timer = setInterval(countdownTimer, 1000);
    });
    onBeforeMount(() => {
      clearInterval(timer);
    });

    return {
      difference,
      errorModal: computed(
        () => store.getters["period/getLatestPeriod"]?.end_date
      ),
      ...toRefs(parts),
      ...useFormatter(),
    };
  },
};
</script>

<style lang="scss" scoped>
.go-to {
  margin-top: 1rem;
  display: inline-block;
  font-size: 2rem;
}
.timer-container {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  text-align: center;
  max-width: 35rem;
  background: #fff;
  border-radius: 1.25rem;

  .day {
    font-size: 3em;
  }
  .time {
    font-size: 3em;
    line-height: 2;
  }
  .descr {
    font-size: 2.125em;
  }
  .tash {
    font-size: 1.2em;
    font-weight: 500;
    margin: 1rem 0;
  }
}
.error {
  padding: 1em;
  font-size: 2em;
  text-align: center;
  max-width: 600px;
  background: #fff;
  margin: 0 auto;
  border-radius: 1.25rem;

  p {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .error {
    font-size: 1.5em;
    text-align: center;
    max-width: 600px;
  }
  .timer-container {
    .content {
    }
  }
}
</style>
