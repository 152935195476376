<template>
  <div class="register-exem">
    <h1 class="welcome-text primary-title" v-if="isIBT">{{ $t('registrationExem.IBT_welcome') }}</h1>
    <h1 class="welcome-text primary-title" v-else>{{ $t('registrationExem.PBT_welcome') }}</h1>
    <div class="select-active">
      <h1 class="select-active-title primary-title">
        {{ $t("registrationExem.select_lvl") }}
      </h1>
      <div class="select-active-btn">
        <PrimaryButton
          :class="{ active: activeTopik === 'topik1' }"
          isOutlined
          @click="selectTopik(1)"
          title="TOPIK 1"
        />
        <PrimaryButton
          :class="{ active: activeTopik == 'topik2' }"
          isOutlined
          @click="selectTopik(2)"
          title="TOPIK 2"
        />
      </div>
    </div>
    <!-- <TimerModal v-if="true" :date="period.start_date" /> -->
    <TransitionGroup name="fade">
      <SuccessModal :data="chekList" v-if="isSuccess" />
      <ErrorModal v-if="isError" :errMsg="isError" />
    </TransitionGroup>
    <Transition name="fade">
      <div v-show="selected">
        <Loader v-show="loading" />
        <form v-if="!loading && !isError" @submit.prevent="registerExam">
          <div class="exam container">
            <div class="grid__center transition" :class="{ animate }">
              <div class="radio-title primary-title">
                {{ $t("registrationExem.location") }}
              </div>
              <label
                class="container-checkbox"
                :class="{ 'checkmark-disabled': item.vacant_places <= item.appeals_count }"
                v-for="(item, index) in period[activeTopik]"
                :key="index"
              >
                <input
                  v-model="chekList"
                  :value="item"
                  type="radio"
                  name="location"
                />
                <span class="checkmark"></span>
                <div class="exem-info">
                  <div class="location primary-title">
                    {{ item.address }}
                  </div>
                  <div class="information primary-text">
                    <p v-if="item.vacant_places <= item.appeals_count" class="no-places">
                      {{
                        $t("registrationExem.noPlaces")
                      }}
                    </p>
                    <p v-else>
                      {{
                        $t("registrationExem.vacantPlaces", {
                          places: item.vacant_places,
                        })
                      }}
                    </p>
                    <span
                      >{{ $t("registrationExem.time") }}
                      {{ handleTime(item.exam_time) }}&nbsp;
                      {{ handleDate(item.exam_time) }}
                    </span>
                    <p>
                      {{
                        $t("registrationExem.payment_place", { payment_place: $t([6, 7, 13].includes(item?.region.id) ? "registrationExem.payment_places.fergana" : "registrationExem.payment_places.tashkent")})
                      }}
                      <!-- {{
                        $t("registrationExem.payment_place", { payment_place: $t("registrationExem.payment_places.tashkent")})
                      }} -->
                    </p>
                  </div>
                </div>
              </label>
            </div>
            <div class="send-btn" :class="{ animate }">
              <PrimaryButton :title="$t('buttons.sendMsg')" />
            </div>
          </div>
        </form>
      </div>
    </Transition>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
// import BackgrounImage from "@/components/registerExem/BackgrounImage.vue";
import { ref, onBeforeUnmount, onMounted, computed, defineExpose } from "vue";
import { useStore } from "vuex";
import { useFormatter } from "@/utils/formatter";
import { useRouter } from "vue-router";
import { useLanguage } from "@/utils/language";
import TimerModal from "@/components/registerExem/TimerModal.vue";
import SuccessModal from "@/components/registerExem/SuccessModal.vue";
import ErrorModal from "../components/registerExem/ErrorModal.vue";
import PrimaryButton from "../components/PrimaryButton.vue";
import Loader from "../components/loader.vue";

const animate = ref(false);
const store = useStore();
const latestPeriodId = computed(() => store.getters["period/getPeriodId"]);
const token = computed(() => store.getters["auth/getAccessToken"]);
// const topik1 = computed(() => store.getters["period/getPeriod"].topik1);
// const topik2 = computed(() => store.getters["period/getPeriod"].topik2);

const period = computed(() => store.getters["period/getPeriod"]);
const showTimer = computed(() => {
  const startDate = store.getters["period/getPeriod"].has_started;
  // const boolParams = new Date(startDate) - new Date() < 0;

  return startDate;
});

const chekList = ref(null);
const isSuccess = ref(false);
const isError = ref("");
const selected = ref(false);
const activeTopik = ref("");

const { handleDate, handleTime } = useFormatter();

const router = useRouter();

const { i18nRoute } = useLanguage();

defineExpose({
  latestPeriodId,
});

// const getPeriodID = (id) => {
//   store.dispatch("period/periodId", {
//     cb: () => {},
//     id,
//     token: token.value,
//   });
// };

const selectTopik = (val) => {
  selected.value = true;
  if (val === 1) {
    activeTopik.value = "topik1";
  } else if (val === 2) {
    activeTopik.value = "topik2";
  }
};

const loading = ref(true);
const isIBT = router.currentRoute.value.name.endsWith("RegistrationIBT");

const getLastPeriod = () => {
  loading.value = true;
  store.dispatch("period/getLatestPeriod", {
    cb: (data) => {
      loading.value = false;
      if (data.has_ended) {
        isError.value = "period_is_closed";
        return;
      }
      if (!data.period_list || !data.has_started) {
        isError.value = "has_not_started";
        return;
      }
    },
    token: token.value,
    isIBT: isIBT,
    err: () => {
      // console.log(e);
      loading.value = false;
      router.push(
        i18nRoute({
          name: "Register",
        })
      );
    },
  });
};

getLastPeriod();

const registerExam = () => {
  isSuccess.value = false;
  if (chekList.value) {
    store.dispatch("period/createAppeal", {
      id: chekList.value.id,
      token: token.value,
      cb: (data) => {
        if (data.success) {
          isSuccess.value = true;
        }
        if (data.error) {
          isError.value = data.error;
        }
      },
      err: (e) => {
        isError.value = e;
        console.log(e);
      },
    });
  }
};
onMounted(() => {
  document.querySelector("html").setAttribute("data-scroll", "scrollPage");
  setTimeout(() => {
    animate.value = true;
  }, 100);
});
onBeforeUnmount(() => {
  document.querySelector("html").removeAttribute("data-scroll");
});
</script>

<style lang="scss" scoped>
html[data-scroll="scrollPage"] {
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 992px) {
  }
}
.prompt {
  position: relative;
  color: var(--secondary-font-color);
  font-size: 1.2em;
  padding: 0.8rem 2rem;
  text-align: center;
  // width: 100%;
  // width: max-content;
  background: linear-gradient(
    90deg,
    rgba(69, 3, 98, 0.5) -0.58%,
    rgba(111, 101, 235, 0.5) -0.58%,
    rgba(51, 4, 92, 0.5) 91.62%,
    rgba(75, 0, 130, 0.5) 100%
  );
  backdrop-filter: blur(19.7186px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14.7889px;
  margin-top: 3rem;
  .triangle {
    position: absolute;
    top: -1.56rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.register-exem {
  padding-top: 3.75rem;
  .transition {
    transition: all 0.6s;
    transform: translateY(-100vh);
    &.animate {
      transform: translateY(0);
    }
  }

  .exam {
    max-width: 40rem;
    margin: 0 auto;
  }

  .active {
    background: var(--primary-blue);
    color: var(--white);
  }

  .select-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-bottom: 2rem;
      font-size: 3rem;
    }
    .select-active-btn {
      display: flex;
      max-width: 40rem;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      margin-bottom: 3rem;
      padding: 0 0.94rem;
    }
  }

  h1.welcome-text {
    width: 100%;
    padding: 1em;
    padding-top: 0;
    opacity: 0.84;
    text-align: center;
  }

  .radio-title {
    font-weight: 500;
    margin-bottom: 1em;
    text-align: center;
  }

  .title {
    text-align: center;
    font-size: 1.7em;
    line-height: 1.5;
    font-weight: 600;
    max-width: 980px;
    margin: 1em auto;
  }
  .send-btn {
    // max-width: var(--container-width);
    margin: 1em 0;
    font-size: 1.4em;
    transition: all 0.6s;
    transform: translateX(-200%);
    width: 100%;
    grid-column: 1 / span 24;
    // border-radius: 100px;
    // overflow: hidden;

    &.animate {
      transform: translateX(0);
    }
    .gradient-btn {
      line-height: 1.7;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(11, 1fr var(--gap)) 1fr;

    // overflow: hidden;
    &__left {
      grid-column: 1/9;
      position: relative;

      .BackgrounImage {
        position: relative;
        margin-left: 5em;
      }
    }
    &__right {
      grid-column: 10/24;
      min-height: 50vh;
    }
  }
}
.mt-1 {
  margin-top: 1em;
}
.spacer {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -1.5em;
    left: -2em;
    height: 2px;
    width: calc(100% + 2em);
    background: #c5c5c5;
    border-radius: 3px;
    margin-bottom: 1em;
  }
}
.exem-info {
  font-size: 1.25em;
  transform: translateY(-0.25em);
  .location {
    font-weight: 500;
  }
  .information {
    font-size: 0.85em;

    .no-places { 
      color: var(--error-color);
      font-weight: bold;
    }
  }
}

.container-checkbox {
  display: block;
  position: relative;
  margin-bottom: 1em;
  cursor: pointer;
  font-size: 1.2em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 2em;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.1em;
    width: 1.1em;
    border: 1px solid #595959;
    box-sizing: border-box;
    border-radius: 50%;
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1.15em;
    width: 1.15em;
    background: var(--primary-blue);
    border-radius: 50%;
  }
}

.checkmark-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

@media screen and (max-width: 1200px) {
  .register-exem {
    .send-btn {
      margin: 1.5em auto;
    }
    .grid {
      max-width: 90%;
      margin: 0 auto;
      &__left {
        grid-column: 1/5;
        .BackgrounImage {
          display: none;
        }
      }
      &__right {
        grid-column: 6/24;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .register-exem {
    font-size: 14px;

    .select-active {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .register-exem {

    .select-active {
   
    .select-active-btn {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 3rem;
      padding: 0 0.94rem;
    }
  }
    .send-btn {
      .btn {
        line-height: 1;
        padding: 0.4em 0;
        width: 100%;
        font-weight: 500;
      }
    }
    .container-checkbox {
      margin-bottom: 0;
      margin-top: 0;
    }
    .spacer {
      // display: none;
      &::after {
        display: none;
      }
    }
    .grid {
      max-width: 90%;
      margin: 0 auto;
      display: block;
      &__left {
        .radio-buttons {
          display: flex;
          align-items: center;
          margin: 1.5em 0;
          gap: 1em;
          .container-checkbox:first-child {
            padding-right: 1em;
            border-right: 2px solid #c5c5c5;
          }
        }
        .BackgrounImage {
          display: none;
        }
      }
      &__right {
        grid-column: 6/24;
        min-height: auto;

        .container-checkbox {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .register-exem {
    .select-active {
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
